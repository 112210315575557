/* eslint-disable react/sort-comp */
/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import IdleTimer from 'react-idle-timer';
import { Provider } from 'react-redux';
import store from './store/store';

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword'));
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword'));
class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.state = {
      autologout: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      this.logoutCheck();
    }
  }

  _onAction() {
    // console.log('user did something');
    localStorage.setItem('userlastactive', this.idleTimer.getLastActiveTime());
  }

  // eslint-disable-next-line class-methods-use-this
  _onActive() {
    //  console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  logoutCheck = () => {
    const lastActiveTime = new Date(
      Number(localStorage.getItem('userlastactive')),
    );
    const dateNow = new Date();
    const timeDiff = Math.abs(dateNow.getTime() - lastActiveTime.getTime());
    const timeDiffInSecond = Math.ceil(timeDiff / 1000);
    if (timeDiffInSecond > 900) {
      localStorage.removeItem('token');
      localStorage.removeItem('usertoken');
      this.setState({ autologout: true }, () => {
        this.setState({ autologout: false });
      });
    }
  };

  _onIdle(e) {
    this.logoutCheck();

    /*
    var date1 = new Date(this.idleTimer.getLastActiveTime());
    console.log(date1);
    var date2 = new Date(); // mm/dd/yyyy format
    var timeDiff = Math.abs(date2.getTime() - date1.getTime()); // in miliseconds
    var timeDiffInSecond = Math.ceil(timeDiff / 1000); // in second
    console.log(timeDiffInSecond);
    */
  }

  render() {
    const checkAuth = () => {
      if (!localStorage.getItem('usertoken')) {
        return false;
      }
      return true;
    };

    const PrivateRoute = ({ component: HomeComponent, ...rest }) => (
      <Route
        {...rest}
        render={(props) => (checkAuth() && !this.state.autologout ? (
          <HomeComponent {...props} />
        ) : (
          <Redirect to="/login" />
        ))}
      />
    );
    return (
      <Provider store={store}>
        <div>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={1000 * 60 * 15}
          />
          <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact={false}
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  exact
                  path="/forgotpassword"
                  name="Forgot Password"
                  render={(props) => <ForgotPassword {...props} />}
                />
                <Route
                  exact
                  path="/resetpassword/:token"
                  name="Reset Password"
                  render={(props) => <ResetPassword {...props} />}
                />
                <PrivateRoute
                  exact={false}
                  path="/"
                  name="Home"
                  component={DefaultLayout}
                />
              </Switch>
            </React.Suspense>
          </HashRouter>
        </div>
      </Provider>
    );
  }
}

export default App;
