import { UPDATE_FILTER } from '../actions/Types';

const initialState = {
  filtered: [],
  filterValue: 'all',
  currentPage: 0,
  pageSize: 10,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        filtered: action.payload.filtered,
        filterValue: action.payload.filterValue,
        currentPage: action.payload.currentPage,
        pageSize: action.payload.pageSize,
      };
    default:
      return state;
  }
}
